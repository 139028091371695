<template>
  <div class="zkcj" v-loading="loading">
    <!-- <h3 class="zkName">{{ zhoukaoName }}</h3> -->
    <h3 class="mark"></h3>
    <Fenxi :fenxi="fenxi"></Fenxi>
    <StuAddCard
      v-for="(item, index) in stus"
      :key="index"
      :title="item.name"
      :number="item.number"
      :className="className"
      :zhoukaoName="zhoukaoName"
      :tel="item.phone"
      :val="item.chengji ? Number(item.chengji) : 0"
      :id="item.t_id ? (''+item.t_id) : '-1'"
    ></StuAddCard>
  </div>
</template>
<script>
import StuAddCard from "@/components/StuAddCard.vue";
import Fenxi from "@/components/fenxi.vue";
export default {
  name: "ZKCJ",
  data() {
    return {
      stus: [],
      className: "",
      zhoukaoName: "",
      fenxi: {},
      loading:false
    };
  },
  async created() {
    this.loading = true;
    this.zhoukaoName = this.$route.query.item.zhoukaoName;
    this.$datas.meta = { zhoukaoName: this.zhoukaoName };
    let res = await this.$datas.getScores;
    if (res.data.t_scores.length <= 0) {
      this.$datas.meta = { className: this.$route.params.className };
      this.className = this.$route.params.className;
      res = await this.$datas.getStus;
      if (res.data.msg.status != 3000) {
        this.stus = res.data.msg.data;
      }
    } else {
        this.className = this.$route.params.className;
        this.stus = res.data.t_scores.map((item) => ({
            name: item.stu.stu_name,
            number: item.stu.stu_id,
            chengji: item.kaoshi,
            phone:item.stu.tel,
            t_id: item.id,
            kind:item.stu.kind
        }));
        for(let i in this.stus){
            if(!this.stus[i].kind){
                this.stus.splice(i, 1);
            }
        }
    }
    //数据分析
    let youxiu = 0;
    let jige = 0;
    let bujige = 0;
    for (let i in this.stus) {
      let item = this.stus[i];
      if (item.chengji >= 90) {
        youxiu++;
      }
      if (item.chengji > 60) {
        jige++;
      } else {
        bujige++;
      }
    }
    this.fenxi = {
      totle: this.stus.length,
      youxiu,
      jige,
      bujige,
    };
    this.loading = false;
  },
  components: {
    StuAddCard,
    Fenxi,
  },
};
</script>
<style lang="less" scoped></style>
